import React from "react"

import { MainLayout } from "../components/containers"
import { Container } from "react-bootstrap"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <MainLayout>
    <SEO title="404: Not found" />
    <Container>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </MainLayout>
)

export default NotFoundPage
